import Modal from "./Modal";

const PortfolioModal = ({ close, open }) => {

  function handleClick1() {
    window.open('/img/portfolio/portada.png');
  }
  function handleClick2() {
    window.open('img/portfolio/portada2.png');
  }
  function handleClick3() {
    window.open('/img/portfolio/portada3.png');
  }
  function handleClick4() {
    window.open('/img/portfolio/portada4.png');
  }

  return (
    <Modal close={close} open={open}>
      <div className="portfolio_popup_details">
        <div className="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div className="main" data-img-url="img/portfolio/portada2.png">
          <a className="edrea_tm_full_link zoom" href="#" onClick={handleClick2}/>
          </div>
        </div>
        <div className="portfolio_main_title">
          <h3>Proyecto I.E.S Tablero</h3>
          <span>
            <a href="https://iestablero.joseluiscaravaca.com/">Acceder a la Web</a>
          </span>
          <div />
        </div>

        <div className="text">
          <p>
          ¡Bienvenidos a mi último proyecto en desarrollo! Esta innovadora aplicación web está diseñada para un instituto de peluquería y estética, permitiendo la gestión eficiente de citas, tratamientos, clientes y personal docente, todo en un solo lugar. Gracias a React en el frontend y Laravel en el backend, junto con una base de datos MySQL, esta solución es robusta, escalable y fácil de mantener.
          </p>
          <p>
          La plataforma cuenta con un calendario intuitivo, en el cual se pueden asignar nuevas citas a clientes y alumnos con tan solo hacer clic en el día deseado. Además, su interfaz amigable y eficiente facilita la navegación, gracias a un panel lateral que permite acceder rápidamente a listas de clientes, alumnos y profesores.
          </p>
          <p>
          El sistema incorpora un completo control de roles, asegurando que cada usuario tenga acceso únicamente a la información y funcionalidades que le corresponden. Por ejemplo, los alumnos solo podrán visualizar los clientes asociados a ellos, mientras que los profesores y el administrador tendrán la posibilidad de gestionar la información de todos los usuarios, incluyendo la adición y eliminación de alumnos y profesores.
          </p>
          <p>
          Este proyecto se encuentra en constante evolución y mejora, con la intención de ofrecer siempre la mejor experiencia a sus usuarios. Próximas actualizaciones incluirán nuevas funcionalidades y optimizaciones para seguir adaptándose a las necesidades del instituto de peluquería y estética.
          </p>
          <p>
          ¡Manténganse atentos para seguir el progreso de este emocionante proyecto!          </p>
        </div>
        <div className="additional_images">
          <ul className="gallery_zoom">
            <li>
              <div className="list_inner">
                <div className="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div className="main" data-img-url="img/portfolio/portada.png" />
                  <a className="edrea_tm_full_link zoom" href="#" onClick={handleClick1}/>
                </div>
              </div>
            </li>
            <li>
              <div className="list_inner">
                <div className="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div className="main" data-img-url="img/portfolio/portada3.png" />
                  <a className="edrea_tm_full_link zoom" href="#" onClick={handleClick3}/>
                </div>
              </div>
            </li>
            <li>
              <div className="list_inner">
                <div className="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div className="main" data-img-url="img/portfolio/portada4.png" />
                  <a className="edrea_tm_full_link zoom" href="#" onClick={handleClick4}/>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  );
};
export default PortfolioModal;
