import About from "../components/About";
import Contact from "../components/Contact";
import Home from "../components/Home";
import News from "../components/News";
import Portfolio from "../components/Portfolio";
import Layout from "../layouts/Layout";
import Building from "../components/Building";

const Index = () => {
  return (
    <Layout>
      {/* HOME */}
      <Home />
      {/* /HOME */}
      {/* ABOUT */}
      <About />
      {/* /ABOUT */}
      {/* PORTFOLIO */}
      <Portfolio />
      {/* /PORTFOLIO */}
      {/* NEWS */}
      <News />
      {/* /NEWS */}
      {/* CONTACT */}
      <Contact />
    </Layout>
  );
};
export default Index;
