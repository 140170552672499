import { Fragment, useState } from "react";
import AnimatedText from "./AnimatedText";
import AboutPopup from "./popup/AboutPopup";

const aboutData = {
  firstName: "José Luis",
  lastName: "Caravaca Carretero",
  bithday: "31.05.1997",
  address: "Córdoba, España",
  phn: "+34 648 442 004",
  email: "info@joseluiscaravaca.com",
  serviceLists: [
    "HTML5, CSS3 y SCSS",
    "React y Laravel",
    "AJAX, JSON, API",
    "Git y GitHub",
    "AWS y Docker",
    "Wordpress",
    "Base de datos con Mysql",
    "Adobe XD y Figma",
    "Trabajo en equipo",
    "Código limpio y eficiente",
    "Aprendizaje rápido",
    "Pasión por la tecnología",
    "Diseño responsivo",
    "Curiosidad y creatividad",
    "Accesibilidad web",
    "SEO y optimización",
  ],
  skills: {
    programming: [
      { name: "JavaScript", value: "55", label:"Medio" },
      { name: "PHP", value: "65", label:"Medio Alto" },
      { name: "HTML", value: "75", label:"Alto" },
      { name: "CSS", value: "75", label:"Alto" },
      { name: "Python", value: "20", label:"Empezando" },
      { name: "React", value: "50", label:"Medio" },
      { name: "Laraval", value: "60", label:"Medio Alto" },
    ],
    language: [
      { name: "Español", value: "100", label: "Nativo" },
      { name: "Inglés", value: "66", label: "B2"},
      { name: "Francés", value: "33", label: "A2"},
    ],
  },
  education: [
    { year: "2021 - 2023", unv: "IES Francisco de los Ríos", degree: "Desarrollo de aplicaciones Web" },
    { year: "2016 - 2019", unv: "Universidad de Córdoba", degree: "Ingeniería Informática" },
    { year: "2014 - 2016", unv: "IES Ategua", degree: "Bachillerato de Ciencias" },
  ],
  working: [
    { year: "18 h", company: "Curso de HTML Y CSS", deg: "OpenBootcamp" },
    { year: "4 h", company: "Curso de React para principiantes", deg: "OpenWebinars" },
    { year: "6 h", company: "Curso de PHP para principiantes", deg: "OpenWebinars" },
    { year: "3 h", company: "Curso Online de Laravel 5", deg: "OpenWebinars" },
    { year: "N / A", company: "Certificado B2", deg: "Trinity College London" },
  ],
  partnersLogos: [
    "img/partners/1.png",
    "img/partners/2.png",
    "img/partners/3.png",
    "img/partners/4.png",
  ],
};

const About = () => {
  const [popup, setPopup] = useState(false);
  return (
    <Fragment>
      <AboutPopup
        open={popup}
        close={() => setPopup(false)}
        aboutData={aboutData}
      />
      <div className="edrea_tm_section hidden animated" id="about">
        <div className="section_inner">
          <div className="edrea_tm_about">
            <div className="left">
              <div className="image">
                <img src="img/about/foto_perfil.jpg" alt="" />
                <div className="main" data-img-url="img/about/foto_perfil.jpg" />
              </div>
            </div>
            <div className="right">
              <div className="short">
                <h3 className="name">
                  {aboutData.firstName}{" "}
                  <span className="coloring">{aboutData.lastName}</span>
                </h3>
                <h3 className="job">
                  <AnimatedText />
                </h3>
              </div>
              <div className="text">
                <p>
                  {/* My name is <span>David Parker.</span> I am a graphic designer,
                  and {`I'm`} very passionate and dedicated to my work. With 20
                  years experience as a professional a graphic designer, I have
                  acquired the skills and knowledge. */}
                  {/* ¡Hola! Soy un desarrollador Full Stack junior apasionado por la tecnología 
                  y el diseño. Me encanta crear soluciones innovadoras y atractivas que mejoren la 
                  experiencia del usuario. Desde sitios web elegantes hasta aplicaciones avanzadas, 
                  estoy aquí para ayudarte a hacer realidad tu visión digital. ¡Explora mi portfolio 
                  y descubre cómo puedo ayudarte a llevar tu proyecto al siguiente nivel! */}
                  {/* ¡Hola! Te doy la bienvenida a mi portfolio personal, aquí encontrarás una muestra 
                  de mi trabajo y habilidades. Desde sitios web elegantes hasta aplicaciones 
                  avanzadas, estoy aquí para ayudarte a hacer realidad tu visión digital. 
                  ¡Explora con total libertad y descubre cómo puedo ayudarte a llevar tu proyecto 
                  al siguiente nivel! */}
                  {/* ¡Hola! Bienvenido a mi portfolio personal. Soy desarrollador Full Stack Junior amante de la tecnología y con ganas de
                  y estoy deseando poder empezar con nuevos projectos en los que adquirir nuevas habilidades y conocimientos. 
                  En mi portfolio encontrarás un vistazo a mi trabajo y habilidades. ¡Vamos a crear 
                  algo juntos! */}
                  ¡Saludos! Soy un desarrollador Full Stack Junior con una gran pasión 
                  por la tecnología y la programación. Tengo habilidades tanto de front-end 
                  como de back-end, y estoy ansioso por trabajar en nuevos proyectos que me permitan 
                  seguir aprendiendo y creciendo profesionalmente. Si estás buscando un desarrollador 
                  web Full Stack confiable y dedicado, ¡hablemos!
                </p>
              </div>
              <div className="edrea_tm_button">
                <a href="#" onClick={() => setPopup(true)}>
                  Ver más
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default About;
